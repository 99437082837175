import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useStoreConfig } from '@pylot/config'
import s from '../CustomerImpersonation.module.scss'
import cn from 'classnames'
import {
    useUser,
    getIsSignedIn,
    useLogout
} from '@corratech/pylot-auth-manager'
import { Button } from '@corratech/pylot-ui'

export const ImpersonationBanner = (): JSX.Element | null => {
    const { t } = useTranslation(['auth', 'common'])
    const [isSignInAsCustomer, setIsSignInAsCustomer] = useState(false)
    const {
        base: {
            customer_impersonation: { enabled }
        }
    } = useStoreConfig()
    const isSignedIn = getIsSignedIn()
    const { customer } = useUser()
    const { logout } = useLogout()

    useEffect(() => {
        if (
            enabled &&
            isSignedIn &&
            customer?.email &&
            localStorage.getItem('sign_in_as_customer') &&
            localStorage.getItem('sign_in_as_customer') === '1'
        ) {
            setIsSignInAsCustomer(true)
        } else {
            setIsSignInAsCustomer(false)
        }
    }, [enabled, isSignedIn, customer])

    if (!isSignInAsCustomer) {
        return null
    }

    return (
        <div
            className={cn(
                s['sign-in-as-customer-banner-wrapper'],
                'internal-sign-in-banner',
                'flex flex-wrap justify-center items-center gap-2.5 bg-yellow text-center font-bold text-t-4 p-2.5'
            )}
        >
            {t(
                `Remote shopping assistance: You are currently shopping as ${customer?.firstname} ${customer?.lastname}.`
            )}
            <Button
                type="button"
                className={s.link}
                onClick={() => {
                    logout()
                }}
                aria-label={t('Leave Session')}
            >
                <span>{t('Leave Session')}</span>
            </Button>
        </div>
    )
}
