import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useStoreConfig } from '@pylot/config'
import s from '../CustomerImpersonation.module.scss'
import cn from 'classnames'
import { getIsSignedIn } from '@corratech/pylot-auth-manager/src/utils/getIsSignedIn'
import { useForm } from 'react-hook-form'
import { useAccountInformation } from '@corratech/pylot-account-information/src/hooks/use-account-information'
import { Button } from '@corratech/pylot-ui'
import { useLoginAsCustomer } from './use-login-as-customer'
import { ToastType, useUI } from '@corratech/pylot-ui/context'
import formStyles from '@commonStyles/Forms.module.scss'

export interface SignInAsCustomerVariableInterface {
    email: string
    first_name: string
    last_name: string
}

export const SignInAsCustomer = (): JSX.Element | null => {
    const { t } = useTranslation(['auth', 'common'])
    const [loading, setLoading] = useState(false)
    const {
        base: {
            customer_impersonation: { enabled }
        }
    } = useStoreConfig()
    const { openToast } = useUI()
    const isSignedIn = getIsSignedIn()
    const { getIsValidEmailAddress } = useAccountInformation()
    const { login } = useLoginAsCustomer()

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm<SignInAsCustomerVariableInterface>()

    const onSubmit = async (data: SignInAsCustomerVariableInterface) => {
        try {
            setLoading(true)
            await login({
                email: data?.email,
                first_name: data?.first_name,
                last_name: data?.last_name
            })
            reset()
            setLoading(false)
        } catch (errors: any) {
            setLoading(false)
            if (errors.length) {
                openToast(
                    t(errors?.[0].message),
                    ToastType.Danger,
                    false,
                    true,
                    false,
                    3000
                )
            }
        }
    }

    if (!enabled || isSignedIn) {
        return null
    }

    return (
        <div
            className={cn(s['sign-in-as-customer-wrapper'])}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="text-lg leading-[1.8125rem] text-black font-bold mb-[1.125rem] pl-0.5 md:pl-0">
                {t('Customer Account Login')}
            </div>
            <div className="sign-in-as-customer-form-wrapper">
                <form id="sign-in-as-customer-form" className={formStyles.root}>
                    <div className="form-group">
                        <label className="heading" htmlFor="email">
                            {t('Email*')}
                        </label>
                        <input
                            type="text"
                            placeholder={t('Enter Email Address*')}
                            {...register('email', {
                                required: 'This field is required',
                                validate: getIsValidEmailAddress
                            })}
                            className={cn('form-input', {
                                'border-alert-danger': errors.email
                            })}
                            id="email"
                        />
                        {errors.email && (
                            <span className="error">
                                {t(errors?.email?.message)}
                            </span>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="heading" htmlFor="first_name">
                            {t('First Name')}
                        </label>
                        <input
                            type="text"
                            placeholder={t('Enter First name')}
                            {...register('first_name', {})}
                            className={cn('form-input', {
                                'border-alert-danger': errors.first_name
                            })}
                            id="first_name"
                        />
                        {errors.first_name && (
                            <span className="error">
                                {t(errors?.first_name?.message)}
                            </span>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="heading" htmlFor="last_name">
                            {t('Last Name')}
                        </label>
                        <input
                            type="text"
                            placeholder={t('Enter Last name')}
                            {...register('last_name', {})}
                            className={cn('form-input', {
                                'border-alert-danger': errors.last_name
                            })}
                            id="last_name"
                        />
                        {errors.last_name && (
                            <span className="error">
                                {t(errors?.last_name?.message)}
                            </span>
                        )}
                    </div>
                    <div className="form-group">
                        <Button
                            className="w-full"
                            disabled={loading}
                            loading={loading}
                            type="submit"
                        >
                            {t('Sign In')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}
