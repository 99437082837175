import {
    DEFAULT_CRITERIA_PARAMETER_NAME,
    parseSearchCriteria,
    SearchCriteria,
    SearchCriteriaFilterActions,
    serializeSearchCriteria
} from '@corratech/pylot-filters-and-sort/src/hooks/use-plp-manager'
import Image from '@corratech/pylot-image'
import {
    ConfigurableProduct,
    ConfigurableProductOptionsValues,
    ConfigurableVariant,
    ProductInterface,
    EnteredOptionInput
} from '@pylot-data/pylotschema'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react'
import Skeleton from 'react-loading-skeleton'
import { ProductPrice } from '../ProductPrice/ProductPrice'
import s from './ProductTile.module.scss'
import { ProductStockStatus } from '@pylot-data/enums/ProductStockStatus.d'
import { useProductUI } from '@pylot-data/hooks/product/use-product-ui'
import { VariantSelector } from '@corratech/pylot-variant-selector'
import { buildUrl } from '@pylot-data/routing/urlBuilder'
import { CategoryHierarchyItem } from 'pages/product/[...product_url]'
import { useDataLayerAction } from '@corratech/pylot-tag-manager'
import { MappedBundleItem } from '@components/common/BundleProduct'
import {
    ProductBadge,
    useBadgeData
} from '@components/common/Product/ProductBadge'
import { quantityRelatedPricing } from '../PriceTable'
import { useStoreConfig } from '@config/index'
import ProductCTA from './ProductCTA'

export interface ProductTileProps {
    product: any
    imageParam: {
        width?: number
    }
    className?: string
    isPDP?: boolean
    isPLP?: boolean
    quickAddOption?: string | null
    swatchAttr?: SwatchAttr
    categoryHierarchy?: CategoryHierarchyItem[]
    index?: number // Selected product index from the PLP, required for GA4
    isLoading?: boolean
}

export enum SwatchAttr {
    COLOR = 'color'
}

export interface Attribute {
    __typename: string
    code: string
    value_index: number
    label: string
}

export interface SmallImage {
    __typename: string
    url: string
}

export interface VariantProduct {
    __typename: string
    id: number
    small_image: SmallImage
    sku: string
    stock_status: string
}

export interface Variant {
    __typename: string
    attributes: Attribute[]
    product: VariantProduct
}

export interface ProductUrl {
    productUrlPath: string
    isConfig: boolean
    urlSearchCriteria: string
}

export const LoaderTile = () => (
    <div
        className={cn(
            s['productitem-block'],
            'productitem-block relative bg-white flex pb-2.5 md:py-0 md:flex-col md:gap-y-[1.375rem] md:h-full hover:shadow-[0_0_0.5rem_0] hover:shadow-black/20'
        )}
    >
        <Skeleton
            height={320}
            width={320}
            className={cn(s['product-img'], 'mx-auto w-full aspect-square')}
        />
        <Skeleton count={2} />
        <Skeleton circle width={16} count={2} />
    </div>
)

const truncateProductName = (productName: string, maxLength = 80) => {
    if (!productName) return ''

    const truncatedName =
        productName.length > maxLength
            ? `${productName.substring(0, maxLength)}...`
            : productName

    return truncatedName
}

const bundleProductDetail = (
    isBundle: boolean,
    product: ProductTileProps['product']
) => {
    if (!isBundle || !product?.items) return null

    const entered_options = [] as EnteredOptionInput[]
    const selected_options = [] as string[]
    for (const item of product?.items as MappedBundleItem[]) {
        const isOSS = item?.additional_options?.find(
            (optionDetails) =>
                optionDetails?.product?.stock_status ===
                ProductStockStatus.OutOfStock
        )
        if (isOSS) {
            product.stock_status = ProductStockStatus.OutOfStock
        }
        if (!item.options || process.env.NEXT_PUBLIC_STORE_CODE === 'usgb')
            continue
        for (const option of item?.options || []) {
            if (option?.is_default) {
                entered_options.push({
                    uid: option.uid,
                    value: String(option.quantity)
                })
                selected_options.push(option.uid)
            }
        }
    }
    return { entered_options, selected_options }
}

const getProductUrl = ({
    productUrlPath,
    isConfig,
    urlSearchCriteria
}: ProductUrl): string => {
    return `${productUrlPath}${isConfig ? urlSearchCriteria : ''}`
}

export const PylotProductTile = ({
    product = {},
    imageParam = {},
    className,
    isPDP = false,
    isPLP = false,
    quickAddOption = null,
    swatchAttr = SwatchAttr.COLOR,
    categoryHierarchy,
    index, // Selected product index from the PLP, required for GA4
    isLoading = false
}: ProductTileProps): ReactElement | null => {
    const isConfig = product.__typename === 'ConfigurableProduct'
    const isBundle = product.__typename === 'BundleProduct'
    const isGiftItem = product.__typename === 'GiftCardProduct'
    const { query } = useRouter()
    const search =
        typeof window !== 'undefined' && !isPDP
            ? new URL(window.location.href).search
            : ''
    const {
        base: { sellToUsForPlp }
    } = useStoreConfig()
    const [urlSearchCriteria, setUrlSearchCriteria] = useState(search)
    const dataLayerAction = useDataLayerAction()

    const badgeData = useBadgeData(product)

    const productUrlPath = buildUrl({
        model: product
    })

    const productUrl = getProductUrl({
        productUrlPath,
        isConfig,
        urlSearchCriteria
    })
    const productPath = query?.url_path?.[0]

    const [selectedOption, setSelectedOption] = useState<SearchCriteria | null>(
        null
    )

    const colorsImagesMap = new Map()

    const fullImageUrl = useCallback(
        (imageUrl?: string): string => {
            const widthString = imageParam.width
                ? `&width=${imageParam.width}`
                : ''
            return `${imageUrl}?auto=webp&format=pjpg${widthString}`
        },
        [imageParam.width]
    )

    const [image, setImage] = useState(fullImageUrl(product.small_image?.url))

    // During the changes in URL - convert new filters to product url
    useEffect(() => {
        if (!selectedOption) {
            setUrlSearchCriteria(search)
        }

        if (selectedOption) {
            onVariantChange({
                label: selectedOption.attribute_code,
                value_index: +selectedOption.filter_value
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        query,
        search,
        selectedOption?.attribute_code,
        selectedOption?.filter_value,
        urlSearchCriteria
    ])

    useEffect(() => {
        if (product.small_image?.url) {
            setImage(fullImageUrl(product.small_image?.url))
        }
    }, [fullImageUrl, product.small_image?.url])

    const productImage = useMemo(
        () => (
            <Image
                src={image}
                alt={product.name ? product.name : 'placeholder'}
                // eslint-disable-next-line i18next/no-literal-string
                sizes="(max-width: 768px) 33vw, 25vw"
                style={{ objectFit: 'contain' }}
                width={320}
                height={320}
                className={cn(
                    s['product-img'],
                    'productitem-img mx-auto w-full aspect-square'
                )}
                key={product.id}
            />
        ),
        [image]
    )

    if (isConfig && product.variants) {
        product.variants.map((variant: Variant) => {
            const colorVariant = variant.attributes.find(
                (variant: { code: string }) => variant?.code === swatchAttr
            )

            const img = variant.product.small_image
                ? variant.product.small_image.url
                : false

            if (colorVariant && img) {
                colorsImagesMap.set(colorVariant.label, fullImageUrl(img))
            }
        })
    }

    const onVariantChange = ({
        label,
        value_index
    }: Partial<ConfigurableProductOptionsValues>) => {
        const value = value_index!.toString()
        const img = colorsImagesMap.get(label)

        // expected output: "0"
        if (img) {
            // setSelectedLabel(label!)
            setImage(img)
        }

        const searchCriteria = isPDP
            ? []
            : parseSearchCriteria(
                  query[DEFAULT_CRITERIA_PARAMETER_NAME] as string
              )

        const idxOfChangedAttribute = searchCriteria.findIndex(
            (sAttr) => sAttr.attribute_code === swatchAttr
        )

        let newOption: SearchCriteria
        if (idxOfChangedAttribute !== -1) {
            searchCriteria[idxOfChangedAttribute].filter_value = value
            newOption = searchCriteria[idxOfChangedAttribute]
        } else {
            newOption = {
                attribute_code: swatchAttr,
                filter_action: SearchCriteriaFilterActions.EQ,
                filter_value: value
            }
            searchCriteria.push(newOption)
        }
        const newSearchCriteriaString = `?${DEFAULT_CRITERIA_PARAMETER_NAME}=${decodeURIComponent(
            serializeSearchCriteria(searchCriteria)
        )}`
        setUrlSearchCriteria(newSearchCriteriaString)
        setSelectedOption(newOption)
    }

    useEffect(() => {
        if (isPDP) return
        // get the first configurable option
        const attrConfigurableOption = options?.[0]?.values?.[0]
        // set the first configurable option as selected
        if (
            isConfig &&
            attrConfigurableOption?.value_index &&
            attrConfigurableOption?.label
        ) {
            onVariantChange(attrConfigurableOption)
        }
    }, [])

    const {
        selectedOptions,
        setSelectedOptions,
        variant,
        selectedOptionUIDs,
        options
    } = useProductUI(product)

    /** Function for trigger the PDP page data category and dataLayerAction */
    const handleLinkOnClick = useCallback(() => {
        if (categoryHierarchy?.length) {
            const pdpBreadcrumbCategoriesRaw =
                sessionStorage.getItem('pdpBreadcrumbCategories') ?? '{}'
            const pdpBreadcrumbCategories = JSON.parse(
                pdpBreadcrumbCategoriesRaw
            )
            pdpBreadcrumbCategories[product.sku] = categoryHierarchy
            sessionStorage.setItem(
                'pdpBreadcrumbCategories',
                JSON.stringify(pdpBreadcrumbCategories)
            )
        }

        /* Triggered GA4 or GTM for SEO when user clicks on product present in list or grid */
        dataLayerAction({
            type: 'PRODUCT_CLICK',
            data: {
                ...product,
                brand: product?.commonElement?.product_brand_text,
                index,
                searchTerm: query.q,
                selectedOptions,
                selectedOptionUIDs,
                categoryGroup: {
                    ...(categoryHierarchy?.length &&
                        categoryHierarchy[categoryHierarchy.length - 1])
                }
            }
        })
    }, [
        categoryHierarchy,
        product,
        index,
        query.q,
        selectedOption,
        selectedOptionUIDs,
        dataLayerAction
    ])

    if (!product) return null

    const productPriceRange = isConfig
        ? variant.price_range
        : product.price_range

    const bundleProduct = bundleProductDetail(isBundle, product)
    const badgesData = product?.labels?.split(',') ?? []
    if (product?.stock_status === ProductStockStatus.OutOfStock) {
        badgesData.push('out-of-stock|Out of Stock')
    }

    const selectedProductOptions = isBundle
        ? bundleProduct?.entered_options
        : selectedOptions
    const selectedProductOptionsUIDs = isBundle
        ? bundleProduct?.selected_options
        : selectedOptionUIDs
    const truncatedProductName = truncateProductName(product?.name)
    const isShopNowVisible = !isPLP
        ? true
        : process.env.NEXT_PUBLIC_STORE_CODE === 'usgb'

    const isSellToUsVisible =
        sellToUsForPlp?.enabled &&
        productPath === sellToUsForPlp?.url &&
        product?.sell_to_us_text === 'Yes'
    const isSellToUsNotVisible =
        productPath === sellToUsForPlp?.url &&
        (!sellToUsForPlp?.enabled || product?.sell_to_us_text === 'No')

    return (
        <div
            className={cn(
                s['productitem-block'],
                'productitem-block relative bg-white flex pb-2.5 md:py-0 md:flex-col md:gap-y-[1.375rem] md:h-full hover:shadow-[0_0_0.5rem_0] hover:shadow-black/20',
                className
            )}
        >
            <div
                className={cn(
                    s['productimage-block'],
                    'productimage-block relative w-full max-w-[9.4375rem] md:max-w-none'
                )}
            >
                <Link
                    href={productUrl}
                    className={cn(
                        s['productimg-link'],
                        'productimg-link block max-w-[9.5rem] w-full pt-8 pr-6 pb-2.5 pl-1 md:pt-[1.0625rem] md:pl-[1.125rem] md:pr-5 md:pb-5 md:max-w-none md:relative cursor-pointer',
                        'after:content-[""] after:hidden after:md:block after:w-4/5 after:max-w-[12.5rem] after:mx-auto after:absolute after:inset-x-0 after:bottom-0 after:border-t after:border-solid after:border-new-green-1'
                    )}
                    aria-label={product.name}
                    onClick={handleLinkOnClick}
                >
                    <ProductBadge productBadges={badgeData} />
                    {productImage}
                </Link>
            </div>
            <div
                className={cn(
                    s['product-details'],
                    'productitem-details flex-grow pr-3 md:text-center md:px-5 md:pb-8'
                )}
            >
                <h2
                    className={cn(
                        s['product-name'],
                        'productitem-name inline-flex w-full items-center md:items-start md:justify-center text-xs leading-[1.125rem] font-medium -tracking-[0.015625rem] my-1.5 md:mt-0 md:mb-1 md:text-sm md:leading-6 md:font-bold font-primary normal-case text-grey-1 min-h-[3.875rem] md:min-h-[4rem]'
                    )}
                >
                    <Link
                        href={productUrl}
                        className={cn(
                            s['productname-link'],
                            'productname-link cursor-pointer inline-block no-underline'
                        )}
                        aria-label={product.name}
                        onClick={handleLinkOnClick}
                    >
                        {truncatedProductName}
                    </Link>
                </h2>

                {product.product_type_label && (
                    <div className={s['product-type']}>
                        {product.product_type_label}
                    </div>
                )}

                {product.model_no && (
                    <div className={s['model-no']}>{product.model_no}</div>
                )}
                {isLoading ? (
                    <Skeleton className="mb-1" height={20} width={`${100}%`} />
                ) : (
                    <ProductPrice
                        className={String(product.__typename).toLowerCase()}
                        isGiftItem={isGiftItem}
                        priceRange={productPriceRange}
                        shouldDisplayPriceRange={false}
                        normalPrice={
                            quantityRelatedPricing(
                                JSON?.parse(product?.pricing_table ?? '{}')
                            ) ?? undefined
                        }
                        isPLP
                        isBundle={isBundle}
                        isSellToUs={productPath === sellToUsForPlp?.url}
                        buyPrice={isSellToUsVisible ? product?.buy_price : null}
                    />
                )}

                {isConfig && product.configurable_options && (
                    <div>
                        <VariantSelector
                            disableAll={
                                (product as ConfigurableProduct)
                                    .stock_status ===
                                ProductStockStatus.OutOfStock
                            }
                            variants={product.variants as ConfigurableVariant[]}
                            options={options}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            isPDP={false}
                            excludedOptions={['size']}
                            PLPVariantChangeHandler={onVariantChange}
                        />
                    </div>
                )}
                <ProductCTA
                    isSellToUsVisible={isSellToUsVisible}
                    isShopNowVisible={isShopNowVisible}
                    isSellToUsNotVisible={isSellToUsNotVisible}
                    categoryHierarchy={categoryHierarchy}
                    quickAddOption={quickAddOption}
                    quickAddOptions={options}
                    product={product}
                    productUrl={productUrl}
                    isConfig={isConfig}
                    isBundle={isBundle}
                    selectedProductOptions={selectedProductOptions}
                    setSelectedOptions={setSelectedOptions}
                    variant={variant}
                    selectedProductOptionsUIDs={selectedProductOptionsUIDs}
                />
            </div>
        </div>
    )
}

export const ProductItem = React.memo(
    (props: ProductTileProps) => <PylotProductTile {...props} />,
    (prevProps, newProps) =>
        JSON.stringify(prevProps) === JSON.stringify(newProps)
)

ProductItem.displayName = 'MemoProductItem'

export const WrappedProductItem = ({
    product,
    idx,
    isPLP = true
}: {
    product: ProductInterface
    idx?: number
    isPLP?: boolean
}): JSX.Element => {
    return (
        <div key={product?.sku ?? product?.id}>
            <ProductItem
                product={product}
                index={idx}
                isPDP
                imageParam={{ width: 500 }}
                isPLP={isPLP}
            />
        </div>
    )
}
