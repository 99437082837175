import { useState } from 'react'
import { useRouter } from 'next/router'
import { useCart, mergeCarts } from '@corratech/pylot-cart-manager'
import { graphqlFetch } from '@pylot-data/graphqlFetch'
import type { CustomerToken } from '@pylot-data/fwrdschema'
import { SIGN_IN_AS_CUSTOMER } from '../graphql/signInAsCustomer'
import { useAccount, useAuthUI } from '@corratech/pylot-auth-manager'
import { useAlertMessage } from '@components/common/AlertMessage'
import { useTranslation } from 'next-i18next'

export type LoginArguments = {
    email: string
    first_name?: string
    last_name?: string
    cartId?: string
}

export const useLoginAsCustomer = () => {
    const { mutate: fetchAccountInfo } = useAccount()
    const { data: cartData, mutate: updateCart } = useCart()
    const { locale } = useRouter()
    const { loginCallbacks } = useAuthUI()
    const { renderAlertMessage } = useAlertMessage()
    const { t } = useTranslation(['common'])
    const [isLoading, setIsLoading] = useState(false)
    const cartId = cartData?.data?.cart?.id

    return {
        login: async (params: LoginArguments) => {
            setIsLoading(true)
            const res = await graphqlFetch<
                LoginArguments,
                { signInAsCustomer: CustomerToken }
            >({
                query: SIGN_IN_AS_CUSTOMER,
                variables: { ...params, cartId },
                locale
            })
            if (res.errors) {
                setIsLoading(false)
                throw res.errors
            }
            localStorage.setItem(
                'pylot_token',
                res?.data?.signInAsCustomer?.token!
            )
            localStorage.setItem('sign_in_as_customer', '1')

            let finalCart

            // We don't use getCartId here because we only want to merge the cart if it already exists.
            // In other words, we don't want to create a new empty cart just to merge it into the existing customer cart.
            if (
                localStorage.getItem('cart_id') &&
                localStorage.getItem('cart_id') !== 'undefined'
            ) {
                finalCart = await mergeCarts({
                    guestCartId: localStorage.getItem('cart_id')!,
                    locale
                }).then((res) => updateCart(res, false))
            } else {
                finalCart = await updateCart()
            }

            if (finalCart?.data?.cart?.id) {
                localStorage.setItem('cart_id', finalCart.data.cart.id)
            } else {
                console.log(
                    'No cart id found after logging in. This is typically a server-side issue.'
                )
            }

            await fetchAccountInfo()

            setIsLoading(false)
            //handle login callbacks
            if (loginCallbacks.length) {
                loginCallbacks.forEach((callback) => {
                    if (typeof callback === 'function') {
                        callback()
                    }
                })
            }

            if (finalCart?.data?.cart?.reorder_message) {
                renderAlertMessage({
                    errorHeading: t(finalCart?.data?.cart?.reorder_message)
                        ? t('Error')
                        : null,
                    errorSubHeading: t(finalCart?.data?.cart?.reorder_message)
                        ? t(finalCart?.data?.cart?.reorder_message)
                        : null,
                    successHeading: '',
                    successSubHeading: '',
                    href: '',
                    linkText: ''
                })
            }
        },
        isLoading
    }
}
