import React, { FC, useState, useEffect } from 'react'
import Link from 'next/link'
import s from './Navbar.module.scss'
import { Container, Button } from '@corratech/pylot-ui'
import cn from 'classnames'
import throttle from 'lodash.throttle'
import { useCmsBlocks } from '@pylot-data/hooks/cms/use-cms-blocks'
import { useIsMobileWidth } from '@lib/hooks/useIsMobileWidth'
import { useUI } from '@corratech/pylot-ui/context'
import { ClickOutside } from '@corratech/pylot-utils'
import { usePreloadedData } from '@preload'
import { useStoreConfig } from '@pylot/config'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { CmsBlock, CmsBlocks, Maybe } from '@pylot-data/pylotschema'
import { ImpersonationBanner } from '@components/CustomerImpersonation'

const UserNav = dynamic(() =>
    import('@components/common').then((mod) => mod.UserNav)
)
const MenuBlock = dynamic(() =>
    import('@components/common/MenuBlock').then((mod) => mod.MenuBlock)
)
const RichContent = dynamic(() =>
    import('@corratech/pylot-rich-content').then((mod) => mod.RichContent)
)
const CmsBlockContent = dynamic(() =>
    import('@components/CmsBlockContent').then((mod) => mod.CmsBlockContent)
)
const Searchbar = dynamic(() => import('@components/common/Searchbar'))
const HeaderLogo = dynamic(() => import('@components/icons/HeaderLogo'))
const Cross = dynamic(() => import('@components/icons/Cross'))

type CmsBlockData = Maybe<{
    data?: {
        cmsBlocks?: Maybe<CmsBlocks>
    }
}>

/**
 * Is used to query element by id and get navbar's height as an offset
 * when scrolling to the target element.
 */
export const NAVBAR_ID = 'navbar'

const Navbar: FC<{ displaySearch: boolean }> = ({ displaySearch }) => {
    const [hasScrolled, setHasScrolled] = useState(false)
    const [viewPortToggle, setViewPortToggle] = useState(false)
    const {
        base: {
            url: { baseUrl = '/' }
        }
    } = useStoreConfig()
    const mobileMenuPreload = usePreloadedData(
        'mobileMenu'
    ) as unknown as CmsBlockData
    const desktopMenuPreload = usePreloadedData(
        'desktopMenu'
    ) as unknown as CmsBlockData
    const contactUsPreload = usePreloadedData(
        'contactUs'
    ) as unknown as CmsBlockData

    const { toggleSearch, closeSearch } = useUI()

    const viewChartBannerID = 'live_market_prices_block_pwa'

    const MOBILE_WIDTH = 1023
    const isMobile = useIsMobileWidth(MOBILE_WIDTH)

    const fallbackData = {
        data: {
            cmsBlocks: {
                items: [
                    ...(mobileMenuPreload?.data?.cmsBlocks?.items ?? []),
                    ...(desktopMenuPreload?.data?.cmsBlocks?.items ?? []),
                    ...(contactUsPreload?.data?.cmsBlocks?.items ?? [])
                ]
            }
        }
    }
    const { items } = useCmsBlocks(
        ['megamenu_mobile_pwa', 'megamenu_pwa', 'header_contact_us_block_pwa'],
        {
            fallbackData,
            revalidateOnMount: true,
            revalidateOnFocus: false
        }
    )
    const {
        megamenu_mobile_pwa: mobileItem,
        megamenu_pwa: desktopItem,
        header_contact_us_block_pwa: contactUsItem
    } = items?.reduce<Record<string, Maybe<CmsBlock>>>((prev, curr) => {
        if (curr?.identifier) {
            prev[curr?.identifier] = curr
        }
        return prev
    }, {}) ?? {}

    const { t } = useTranslation()

    useEffect(() => {
        const handleScroll = throttle(() => {
            const mainElement: HTMLElement | null =
                document.getElementById('contentarea')
            const offset = mainElement?.offsetTop
            const { scrollTop } = document.documentElement
            const scrolled = scrollTop > offset!
            setHasScrolled(scrolled)
        }, 200)

        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleSearchToggle = (flag: boolean) => {
        toggleSearch()
        setViewPortToggle(flag)
    }

    const LogoAndNavigation = () => (
        <>
            <div className={s['contact-us-block']}>
                <RichContent html={contactUsItem?.content} />
            </div>
            <Link href={baseUrl} className={s.logo}>
                <HeaderLogo
                    width={138}
                    height={30}
                    className="cursor-pointer w-[8.625rem] h-[1.875rem] lg:w-[11.125rem] lg:h-[2.375rem]"
                />
                <span className="sr-only">{t('USGB Logo')}</span>
            </Link>
            <div className={s['megamenu-cms']}>
                {!isMobile && hasScrolled && desktopItem?.content && (
                    <MenuBlock
                        menu={desktopItem?.content}
                        isDesktopMenu
                        contactUsContent={contactUsItem?.content ?? ''}
                    />
                )}
            </div>
        </>
    )

    return (
        <div
            className={cn(
                s.root,
                hasScrolled && s['shadow-magical'],
                hasScrolled && 'nav-sticky'
            )}
        >
            <ImpersonationBanner />
            <div className={cn(s['view-chart-banner'], 'view-chart-banner')}>
                <CmsBlockContent identifier={viewChartBannerID} />
            </div>
            <div className={s['nav-wrapper']}>
                <Container id={NAVBAR_ID} clean className={s['nav-container']}>
                    <div className={s['nav-container-inner']}>
                        <div className={s['nav-section']}>
                            <LogoAndNavigation />
                            <UserNav
                                handleSearchToggle={handleSearchToggle}
                                contactUsContent={contactUsItem?.content ?? ''}
                            />
                        </div>
                    </div>
                </Container>
            </div>
            <div className={s['megamenu-cms-default']}>
                {!isMobile && !hasScrolled && desktopItem?.content && (
                    <MenuBlock
                        menu={desktopItem?.content}
                        isDesktopMenu
                        contactUsContent={contactUsItem?.content ?? ''}
                    />
                )}
                {isMobile && mobileItem?.content && (
                    <MenuBlock
                        menu={mobileItem?.content}
                        isDesktopMenu={false}
                        contactUsContent={contactUsItem?.content ?? ''}
                    />
                )}
            </div>
            {displaySearch && (
                <ClickOutside active={viewPortToggle} onClick={closeSearch}>
                    <div
                        className={cn(
                            s['search-display'],
                            'animate-fade-in-down'
                        )}
                        id="search-bar"
                    >
                        <Searchbar id="search" />
                        <Button
                            variant="link"
                            type="button"
                            className={s['toggle-search']}
                            onClick={toggleSearch}
                        >
                            <span aria-hidden="true">
                                <Cross />
                            </span>
                        </Button>
                    </div>
                </ClickOutside>
            )}
        </div>
    )
}

export default Navbar
