import cn from 'classnames'
import type { ReactElement } from 'react'
import React from 'react'
import s from './ProductBadge.module.scss'
import { useTranslation } from 'react-i18next'
import { Shield } from '@components/icons'

export type ProductBadge = {
    productBadges: string[]
    isPDP?: boolean
}

export const ProductBadge = ({
    productBadges,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isPDP = false
}: ProductBadge): ReactElement => {
    const { t } = useTranslation(['plp'])

    const currentClass = isPDP ? 'product-badges-pdp' : 'product-badges'

    return (
        <div className={cn(s[currentClass])}>
            {productBadges.map((value) => (
                <span
                    key={`product-badge-${value}`}
                    className={cn(
                        s['product-badge'],
                        s[
                            `product-badge-${value}`
                                .toLowerCase()
                                .replace(/ /g, '-')
                        ]
                    )}
                >
                    {value.toLowerCase().replace(/ /g, '-') ===
                    'ira-eligible' ? (
                        <Shield />
                    ) : null}
                    {t(value.replace(/-/g, ' '))}
                </span>
            ))}
        </div>
    )
}
