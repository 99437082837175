import { ProductInterface } from '@pylot-data/pylotschema'
import { badgePriority } from './BadgePriority'
export const useBadgeData = (product?: ProductInterface): string[] => {
    if (product?.stock_status === 'OUT_OF_STOCK') return ['out-of-stock']

    if (!product?.badge_text) return []

    //Converts the badge data from a JSON string to an array of strings
    const badgeData = JSON.parse(product?.badge_text ?? '[]')

    // Define a type for the enum
    type BadgePriorityKey = keyof typeof badgePriority

    //Sorts the badge data based on the badgePriority ascending
    badgeData?.sort((a: BadgePriorityKey, b: BadgePriorityKey) => {
        const aPriority = (badgePriority?.[a] as number) || 0
        const bPriority = (badgePriority?.[b] as number) || 0
        if (aPriority < bPriority) {
            return -1
        }
        if (aPriority > bPriority) {
            return 1
        }
        return 0
    })

    //returns the sorted badge data first 2 elements
    return badgeData.slice(0, 2)
}
