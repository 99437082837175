import { calculateQtyRange } from './calculateQtyRange'

const getPrice = (value: {
    [key: string]: { [key: string]: string }
}): number => {
    const finalPriceString = Object?.entries(value ?? {})[0]?.[1]?.value?.trim()
    return parseFloat(finalPriceString?.replace(/,/g, ''))
}

export const quantityRelatedPricing = (
    pricingData?: {
        [key: string]: {
            [key: string]: {
                [key: string]: string
            }
        }
    },
    qty?: number
): number => {
    // Based on the quantity we need to get the price
    let finalPrice = 0
    if (!pricingData) return finalPrice

    if (qty) {
        Object.entries(pricingData)?.forEach(([key, value]) => {
            const { isQtyInRange, exciteQty } = calculateQtyRange(key, qty)
            if (isQtyInRange || exciteQty) {
                finalPrice = getPrice(value)
            }
        })
    } else {
        // Get the lowest price from the pricing table
        // Travel through the object and get the lowest price
        let lowestPrice = 0
        Object.entries(pricingData)?.forEach(([, value]) => {
            const price = getPrice(value)
            if (lowestPrice === 0 || price < lowestPrice) {
                lowestPrice = price
            }
        })
        finalPrice = lowestPrice
    }
    return finalPrice
}
