import { useState, useCallback, useEffect } from 'react'

export const useIsMobileWidth = (width: number): boolean => {
    const [targetReached, setTargetReached] = useState(false)

    const updateTarget = useCallback((e) => {
        if (e.matches) {
            setTargetReached(true)
        } else {
            setTargetReached(false)
        }
    }, [])

    useEffect(() => {
        if (typeof window === 'undefined') return
        const media = window.matchMedia(`(max-width: ${width}px)`)
        media.addEventListener('change', updateTarget)

        // Check on mount (callback is not called until a change occurs)
        if (media.matches) {
            setTargetReached(true)
        }

        return () => media.removeEventListener('change', updateTarget)
    }, [])

    return targetReached
}
