const Grid = ({ ...props }) => {
    return (
        <svg
            width="19"
            height="19"
            viewBox="0 0 100 125"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
            x="0"
            y="0"
            {...props}
        >
            <g display="none">
                <rect
                    x="-50"
                    y="-50"
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    width="200"
                    height="200"
                />
                <circle
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    cx="50"
                    cy="50"
                    r="100"
                />
                <rect
                    x="-50"
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    width="200"
                    height="100"
                />
                <line
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    x1="-50"
                    y1="50"
                    x2="150"
                    y2="50"
                />
                <line
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    x1="150"
                    y1="-50"
                    x2="-50"
                    y2="150"
                />
                <line
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    x1="-50"
                    y1="-50"
                    x2="150"
                    y2="150"
                />
                <rect
                    y="-50"
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    width="100"
                    height="200"
                />
                <line
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    x1="50"
                    y1="-50"
                    x2="50"
                    y2="150"
                />
                <rect
                    x="-20.711"
                    y="-20.711"
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    width="141.422"
                    height="141.422"
                />
                <circle
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    cx="50"
                    cy="50"
                    r="70.711"
                />
                <circle
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    cx="50"
                    cy="50"
                    r="25"
                />
                <circle
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    cx="50"
                    cy="50"
                    r="50"
                />
            </g>
            <g display="none">
                <rect
                    x="250"
                    y="-50"
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    width="200"
                    height="200"
                />
                <circle
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    cx="350"
                    cy="50"
                    r="100"
                />
                <rect
                    x="250"
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    width="200"
                    height="100"
                />
                <line
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    x1="250"
                    y1="50"
                    x2="450"
                    y2="50"
                />
                <line
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    x1="450"
                    y1="-50"
                    x2="250"
                    y2="150"
                />
                <line
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    x1="250"
                    y1="-50"
                    x2="450"
                    y2="150"
                />
                <rect
                    x="300"
                    y="-50"
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    width="100"
                    height="200"
                />
                <line
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    x1="350"
                    y1="-50"
                    x2="350"
                    y2="150"
                />
                <rect
                    x="279.289"
                    y="-20.711"
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    width="141.422"
                    height="141.422"
                />
                <circle
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    cx="350"
                    cy="50"
                    r="70.711"
                />
                <circle
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    cx="350"
                    cy="50"
                    r="25"
                />
                <circle
                    display="inline"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="0.1"
                    strokeMiterlimit="10"
                    cx="350"
                    cy="50"
                    r="50"
                />
            </g>
            <g display="none">
                <rect
                    x="429.289"
                    y="5"
                    display="inline"
                    width="90"
                    height="13.18"
                />
                <rect
                    x="429.289"
                    y="81.819"
                    display="inline"
                    width="90"
                    height="13.181"
                />
                <rect
                    x="429.289"
                    y="43.41"
                    display="inline"
                    width="90"
                    height="13.18"
                />
            </g>
            <rect x="38.75" y="38.75" width="22.5" height="22.5" />
            <rect x="5" y="38.75" width="22.5" height="22.5" />
            <rect x="72.5" y="38.75" width="22.5" height="22.5" />
            <rect x="72.5" y="72.5" width="22.5" height="22.5" />
            <rect x="72.5" y="5" width="22.5" height="22.5" />
            <rect x="38.75" y="72.5" width="22.5" height="22.5" />
            <rect x="38.75" y="5" width="22.5" height="22.5" />
            <rect x="5" y="72.5" width="22.5" height="22.5" />
            <rect x="5" y="5" width="22.5" height="22.5" />
        </svg>
    )
}

export default Grid
