export const getCMSBlocks = /* GraphQL */ `
    query getCMSBlocks($identifiers: [String]) {
        cmsBlocks(identifiers: $identifiers) {
            items {
                identifier
                title
                content
            }
        }
    }
`
export default getCMSBlocks
