import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useStoreConfig } from '@pylot/config'
import { Button } from '@corratech/pylot-ui'
import s from './CustomerImpersonation.module.scss'
import cn from 'classnames'
import { InternalOnlyContent } from './InternalOnlyContent'
import { useRouter } from 'next/router'
import { Grid } from '@components/icons'

export const CustomerImpersonation = (): JSX.Element | null => {
    const { t } = useTranslation(['common'])
    const [openInternalTab, setOpenInternalTab] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const {
        base: { customer_impersonation }
    } = useStoreConfig()
    const router = useRouter()

    useEffect(() => {
        const trackClickOutside = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                setOpenInternalTab(false)
            }
        }
        window.addEventListener('click', trackClickOutside)
        return () => window.removeEventListener('click', trackClickOutside)
    }, [ref])

    useEffect(() => {
        setOpenInternalTab(false)
    }, [router.asPath])

    if (!customer_impersonation?.enabled) {
        return null
    }

    const InternalOnly = () => {
        setOpenInternalTab(!openInternalTab)
    }

    return (
        <div
            className={cn(
                s['internal-only-wrapper'],
                `${openInternalTab ? s['open'] : ''}`
            )}
            ref={ref}
        >
            <div className={s['internal-handle-bar']}>
                <Button
                    variant="link"
                    type="button"
                    onClick={() => InternalOnly()}
                    aria-label={t('Internal Only')}
                >
                    <span>{t('Internal Only')}</span>
                    <Grid />
                </Button>
            </div>
            <InternalOnlyContent />
        </div>
    )
}
