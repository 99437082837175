const Shield = ({ ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            {...props}
        >
            <g clipPath="url(#clip0_25085_2599)">
                <path
                    d="M1.51531 3.59063L7.99969 1.07187L14.4841 3.5875C14.5684 5.0125 14.3434 7.2375 13.3997 9.40938C12.4559 11.5813 10.7934 13.7 7.99969 14.9094C5.20594 13.7 3.54344 11.5844 2.59969 9.4125C1.65594 7.24063 1.43094 5.01562 1.51531 3.59063ZM15.4841 3.53125L15.4466 2.89062L14.8466 2.65625L8.36219 0.140625L7.99969 0L7.63719 0.140625L1.15281 2.65625L0.555939 2.89062L0.518439 3.53125C0.427814 5.09062 0.671564 7.47812 1.68406 9.80937C2.70594 12.1625 4.52781 14.4969 7.60281 15.8281L7.99969 16L8.39656 15.8281C11.4716 14.4969 13.2934 12.1625 14.3153 9.80937C15.3278 7.475 15.5716 5.09062 15.4809 3.53125H15.4841ZM11.3528 6.35313L11.7059 6L10.9997 5.29375L10.6466 5.64687L6.99969 9.29375L5.35281 7.64687L4.99969 7.29375L4.29344 8L4.64656 8.35312L6.64656 10.3531L6.99969 10.7063L7.35281 10.3531L11.3528 6.35313Z"
                    fill="#4D4D4D"
                />
            </g>
            <defs>
                <clipPath id="clip0_25085_2599">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Shield
