import React from 'react'
import { useTranslation } from 'react-i18next'
import { calculateQtyRange } from './utils/calculateQtyRange'
import cn from 'classnames'

interface PriceDataValue {
    value: string
}

interface PriceData {
    [key: string]: {
        [key: string]: PriceDataValue
    }
}

interface PriceTableProps {
    className?: string
    priceData: PriceData
    sku: string
    qty?: number
}

const PriceTable: React.FC<PriceTableProps> = ({
    className,
    priceData,
    qty
}) => {
    const { t } = useTranslation(['common', 'pdp'])

    const firstObject = priceData[Object.keys(priceData)?.[0]]

    const columns = Object?.keys(firstObject) ?? []
    columns?.unshift('Quantity')
    const actualData = priceData
    //Get the first key of the object
    const firstKey = Object.keys(actualData)?.[0]
    const startRangeNumber = firstKey?.split(' - ')?.[0]
    const isHidden =
        Object.entries(actualData)?.length <= 1 &&
        (startRangeNumber === '1' || firstKey === 'Any')

    const twClasses = {
        pricingHeadingStyle:
            'text-blue-1 uppercase font-primary font-bold text-xs leading-[0.875rem] tracking-[0.03125rem] mb-2.5 md:mb-3',
        tableHeadStyle:
            'text-xs leading-[0.875rem] text-white bg-gold-1 font-bold uppercase tracking-[0.03125rem] text-left align-top py-[0.6875rem] pl-[1.875rem] pr-2 md:pt-[0.6875rem] md:px-2.5 md:pb-3.5 lg:px-5 xl:px-8',
        tableCellStyle:
            'text-sm leading text-grey-1 font-medium align-top pt-3 pb-[0.9375rem] pr-0 pl-[1.875rem] md:px-2.5 lg:px-5 xl:px-8'
    }

    return (
        <div
            className={cn(
                className,
                `${isHidden ? 'hidden' : 'mt-6 md:mt-10'}`
            )}
        >
            <h3 className={twClasses.pricingHeadingStyle}>{t('Pricing')}</h3>
            <table className="w-full border border-solid border-grey-3 bg-white">
                <caption className="sr-only">{t('Pricing Table')}</caption>
                <thead>
                    <tr>
                        {columns.map((columnName, index) => {
                            return (
                                <th
                                    key={index}
                                    scope="col"
                                    className={twClasses.tableHeadStyle}
                                >
                                    {columnName}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(priceData)?.map(([key, value]) => {
                        const { isQtyInRange, exciteQty } = calculateQtyRange(
                            key,
                            qty
                        )
                        const rowClassName =
                            isQtyInRange || exciteQty ? 'qty-range' : ''

                        return (
                            <tr key={key} className={rowClassName}>
                                <td className={twClasses.tableCellStyle}>
                                    {key.replace(' and more', '+')}
                                </td>
                                {Object?.entries(value)?.map(
                                    ([, childValue], index) => {
                                        const typedChildValue =
                                            childValue as PriceDataValue
                                        if (typedChildValue) {
                                            return (
                                                <td
                                                    key={index}
                                                    className={
                                                        twClasses.tableCellStyle
                                                    }
                                                >
                                                    ${typedChildValue.value}
                                                </td>
                                            )
                                        }
                                        return null
                                    }
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default PriceTable
