import { formatPrice, usePrice } from '@corratech/pylot-price'
import type { PriceRange } from '@pylot-data/pylotschema'
import cn from 'classnames'
import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

export type ProductPrice = {
    priceRange: PriceRange
    className?: string
    shouldDisplayPriceRange?: boolean
    isGiftItem?: boolean
    normalPrice?: number
    isPLP?: boolean
    isBundle?: boolean
    isSellToUs?: boolean
    buyPrice?: number
}

export const ProductPrice = ({
    priceRange,
    className,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldDisplayPriceRange = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isGiftItem = false,
    normalPrice,
    isPLP = false,
    isBundle = false,
    isSellToUs = false,
    buyPrice
}: ProductPrice): ReactElement => {
    const { locale = 'en' } = useRouter()

    const currency = priceRange?.minimum_price?.final_price?.currency
    const { total } = usePrice(priceRange)
    const { t } = useTranslation(['common', 'pdp'])

    const totalNumber = parseFloat(total?.split('$')?.[1]?.replace(/,/g, ''))

    const withoutDiscountPrice =
        priceRange?.minimum_price?.regular_price?.value ?? totalNumber

    const normalPriceCalculation = useMemo(() => {
        if (normalPrice) {
            return normalPrice
        } else {
            //Separatexps the total from $ sign
            const totalValue = total?.split('$')?.[1]
            //Remove the comma from the total
            const totalValueWithoutComma = totalValue?.replace(/,/g, '')
            //Convert the total to number
            const totalValueNumber = parseFloat(totalValueWithoutComma)
            return totalValueNumber
        }
    }, [normalPrice, total])

    const isGreenForNonPLP = () => {
        if (normalPrice === withoutDiscountPrice || normalPrice === 0) {
            return true
        }
    }

    const isRedForPLP = () => {
        if (
            normalPrice !== 0 &&
            (normalPrice || false) < withoutDiscountPrice
        ) {
            return true
        }

        return false
    }

    const priceClass = useMemo(() => {
        if (isSellToUs) {
            return 'text-grey-1'
        }
        if (!isPLP) {
            if (isGreenForNonPLP()) {
                return 'text-new-green-1'
            } else {
                return 'text-red'
            }
        } else if (isPLP) {
            if (isRedForPLP()) {
                return 'text-red'
            }
            return 'text-grey-1'
        }
        return 'text-new-green-1'
    }, [normalPrice, totalNumber])

    const getPrice = () => {
        if (buyPrice) {
            return (
                <strong className="font-extrabold">
                    {formatPrice(
                        {
                            value: buyPrice,
                            currency
                        },
                        locale
                    )}
                </strong>
            )
        } else if (!isSellToUs) {
            return (
                <strong className="font-extrabold">
                    {isPLP &&
                    normalPrice &&
                    normalPrice !== 0 &&
                    normalPrice < withoutDiscountPrice &&
                    !isBundle ? (
                        <span className="non-discount-price line-through">
                            {
                                //Add comma to the price
                                formatPrice(
                                    {
                                        value: withoutDiscountPrice,
                                        currency
                                    },
                                    locale
                                )
                            }
                        </span>
                    ) : (
                        ''
                    )}
                    {
                        //Add comma to the price
                        formatPrice(
                            {
                                value: normalPriceCalculation,
                                currency
                            },
                            locale
                        )
                    }
                </strong>
            )
        } else {
            return null
        }
    }

    const getPriceLabel = () => {
        if (isSellToUs && buyPrice) {
            return <span className="price-note">{t('Payout')}</span>
        }
        if (isPLP && normalPrice && !isSellToUs) {
            return <span className="price-note">{t('As low as')}</span>
        }
        return ''
    }

    return (
        <div className={cn(['product-price', className])}>
            <span
                className={cn(['product-price--value', className, priceClass])}
            >
                {getPriceLabel()}
                {getPrice()}
                {!isPLP && `/ ${t(' unit')}`}
            </span>
        </div>
    )
}
