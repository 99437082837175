import { Maybe } from '@graphql-tools/utils'

export type FormConfig = Maybe<{ formId: string }>

export type ConfigResult = {
    node: Document
    data: {
        mageMeWebform: any[]
        owlCarouselItems: CarouselData[]
    }
}

export type CarouselData = {
    items: (string | null)[]
    config: any
    id?: string
    itemClassName?: string
    linkedDOM?: HTMLElement
}
function getMageMeWebforms(node: Document) {
    const mageMeWebform: FormConfig[] = []

    node?.querySelectorAll<HTMLInputElement>(
        '.webforms [name="form_id"]'
    )?.forEach((webform: HTMLInputElement) => {
        mageMeWebform.push({ formId: webform.value ?? '' })
        webform.innerHTML = ``
    })
    return mageMeWebform
}
const safeParse = (jsonString: string) => {
    return new Function(`return ${jsonString}`)()
}

function extractOwlCarouselConfig(node: Document, codeString: string) {
    const owlCarouselItems: CarouselData[] = []
    const carouselConfigs: { [key: string]: any } = {}
    const owlCarousels = node?.querySelectorAll<HTMLElement>('.owl-carousel')
    if (owlCarousels?.length) {
        try {
            const configMatches = codeString.matchAll(
                /\$\('#([\w-]+)\.owl-carousel'\)\.owlCarousel\(([\s\S]+?)\);/g
            )
            for (const match of configMatches) {
                const [, carouselId, configString] = match
                carouselConfigs[String(carouselId)] = safeParse(configString)
            }
        } catch (e) {
            // eslint-disable-next-line no-empty
        }
    }

    owlCarousels.forEach((owlCarousel: HTMLElement) => {
        const config = carouselConfigs[owlCarousel.id]
        if (config) {
            const itemsNode = Array.from(
                owlCarousel.querySelectorAll<HTMLElement>('.item')
            )
            let itemClassName: boolean | string = false
            const items: (string | null)[] = itemsNode.map((item) => {
                if (itemClassName === false && item?.className) {
                    itemClassName = item?.className
                }
                return item.innerHTML
            })

            owlCarouselItems.push({
                items: items,
                config: config,
                id: String(owlCarousel?.id),
                itemClassName: !itemClassName ? 'item' : String(itemClassName),
                linkedDOM: owlCarousel
            })
        }
    })
    return owlCarouselItems
}

export const getConfig = (node: Document): ConfigResult => {
    const codeString = String(node?.body?.innerHTML) || ''
    const mageMeWebform: any[] = getMageMeWebforms(node)

    const owlCarouselItems = extractOwlCarouselConfig(node, codeString)

    return {
        node: node,
        data: {
            mageMeWebform: mageMeWebform,
            owlCarouselItems: owlCarouselItems
        }
    }
}
