export const SIGN_IN_AS_CUSTOMER = /* GraphQL */ `
    mutation signInAsCustomer(
        $email: String!
        $first_name: String
        $last_name: String
    ) {
        signInAsCustomer(
            input: {
                email: $email
                first_name: $first_name
                last_name: $last_name
            }
        ) {
            token
        }
    }
`
