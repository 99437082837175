import React, { useEffect } from 'react'
import { RichContent } from '@corratech/pylot-rich-content'
import { useCmsBlocks } from '@pylot-data/hooks/cms/use-cms-blocks'
import { useTranslation } from 'next-i18next'
import { useStoreConfig } from '@pylot/config'
import { CmsBlock, Maybe } from '@pylot-data/fwrdschema'
import { useLogout } from '@corratech/pylot-auth-manager'
import { ProductItem } from './ProductTile/ProductTile'
import Link from 'next/link'
import { Button } from '@corratech/pylot-ui'
import s from './CustomerImpersonation.module.scss'
import { getIsSignedIn } from '@corratech/pylot-auth-manager/src/utils/getIsSignedIn'

export const InternalOnlyContent = (): JSX.Element | null => {
    const { t } = useTranslation(['common'])
    const {
        base: { customer_impersonation }
    } = useStoreConfig()
    const isSignedIn = getIsSignedIn()
    const { logout } = useLogout()

    useEffect(() => {
        getIsSignedIn()
    }, [logout])

    const { items } = useCmsBlocks(
        [
            customer_impersonation?.quick_links,
            customer_impersonation?.popular_items
        ],
        {
            revalidateOnMount: true,
            revalidateOnFocus: false
        }
    )

    const mappedItems: Record<string, Maybe<CmsBlock>> = {}
    items?.forEach((item) => {
        if (item?.identifier && item?.content) {
            mappedItems[item.identifier] = item
        }
    })

    return (
        <div className={s['internal-content-wrapper']}>
            <div className="internal-left">
                <div className="internal-left-section">
                    <h4>{t('Administration')}</h4>
                    <ul>
                        <li>
                            {isSignedIn ? (
                                <Button
                                    variant="link"
                                    type="button"
                                    onClick={() => logout()}
                                    aria-label={t('Sign Out')}
                                >
                                    <span>{t('Sign Out')}</span>
                                </Button>
                            ) : (
                                <Link href="/cart">
                                    {t('Sign In As Customer')}
                                </Link>
                            )}
                        </li>
                    </ul>
                </div>
                <div className="internal-left-section">
                    <h4>{t('Quick Links')}</h4>
                    <div className="cms-content">
                        <RichContent
                            html={
                                mappedItems[customer_impersonation?.quick_links]
                                    ?.content
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="internal-right">
                <h4>{t('Popular Items')}</h4>
                <div className="cms-content">
                    <RichContent
                        html={
                            mappedItems[customer_impersonation?.popular_items]
                                ?.content
                        }
                        ProductTile={ProductItem}
                    />
                </div>
            </div>
        </div>
    )
}
