import dynamic from 'next/dynamic'
import s from './Layout.module.scss'
import React, { FC } from 'react'
import { useUI } from '@corratech/pylot-ui/context'
import { Navbar } from '@components/common'
import { useAcceptCookies } from '@corratech/pylot-utils'
import { Button } from '@corratech/pylot-ui'
import { LoadingIndicator } from '@components/LoadingIndicator'
import { useTranslation } from 'next-i18next'
import { AuthWrapper } from '@components/common/AuthWrapper'
import { CustomerImpersonation } from '@components/CustomerImpersonation'

const FeatureBar = dynamic(() => import('@components/common/FeatureBar'), {
    // eslint-disable-next-line react/display-name
    loading: () => <LoadingIndicator />
})

const Footer = dynamic(() => import('@components/common/Footer'), {
    ssr: true
})

const LayoutAuthModal = dynamic(() => import('./LayoutAuthModal'), {
    ssr: false
})

interface Props {
    authRequired: boolean
    pageProps: any
}
const Layout: FC<Props> = ({ children, authRequired }) => {
    const { displaySearch } = useUI()
    const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
    const { t } = useTranslation()
    return (
        <div className="mx-auto">
            <a className={s['skip-to-content']} href="#contentarea">
                {t('Skip to Content')}
            </a>

            <Navbar displaySearch={displaySearch} />
            <main className="fit" id="contentarea">
                <AuthWrapper authRequired={authRequired}>
                    <CustomerImpersonation />
                    {children}
                </AuthWrapper>
            </main>

            <Footer />
            <LayoutAuthModal />
            <FeatureBar
                title={t(
                    'This site uses cookies to improve your experience. By clicking, you agree to our Privacy Policy.'
                )}
                hide={acceptedCookies}
                action={
                    <Button
                        className="mx-5"
                        type="button"
                        onClick={() => onAcceptCookies()}
                    >
                        {t('Accept cookies')}
                    </Button>
                }
            />
        </div>
    )
}

export default Layout
