/*  IMPORTANT:
    Ensure that any fields which are needed for these rules are also included
    in the `routes` graphql request in `framework/pylot/routing/getRouteForPath.ts`
    Otherwise, redirects will not function properly.
 */

import { ProductTypeEnum } from '@pylot-data/enums/ProductTypeEnum.d'
import type {
    ProductInterface,
    GiftCardProduct,
    CategoryTree,
    BundleProduct,
    CmsPage
} from '@pylot-data/pylotschema'
import type { PylotFrontendConfig } from 'config'
import { getStoreConfig } from 'config'

const PRODUCT_URL_STRUCTURE = ['product', '[url_key]']
const GIFT_CARD_URL_STRUCTURE = ['gift-card', '[url_key]']
const BUNLDE_PRODUCT_URL_STRUCTURE = ['bundle', '[url_key]']
const CATEGORY_URL_STRUCTURE = ['category', '[url_path]']
const CMS_URL_STRUCTURE = ['content', '[identifier]']

const SLUG_DELIMITER = '['

type TInput =
    | (ProductInterface & { __typename?: string })
    | CategoryTree
    | CmsPage
    | undefined
    | null

interface IBaseUrlParam {
    config?: PylotFrontendConfig
    absolute?: boolean
}

interface ISlugOverride {
    [key: string]: {
        newSlug?: string
        newValue?: string
        resolver?: () => string
    }
}

interface IOptions {
    slugOverride?: ISlugOverride
    config?: PylotFrontendConfig
    absolute?: boolean
    urlStructure?: string[]
    locale?: string
}

interface IInputArgs {
    model: TInput
    options?: IOptions
}

interface IUrlHandler {
    model: TInput
    urlStructure: string[]
    options?: IOptions
}

interface IGetUrlParam {
    model: TInput
    options?: IOptions
}

interface IHandlers {
    canHandle: (model: TInput) => boolean
    getUrl: (params: IGetUrlParam) => string
}

export const isGiftCard = (product: TInput): boolean => {
    const productType = (product as GiftCardProduct)?.__typename as
        | ProductTypeEnum
        | undefined
    return productType === ProductTypeEnum.GIFT_CARD_PRODUCT
}

export const isBundleProduct = (product: TInput): boolean => {
    const productType = (product as BundleProduct)?.__typename as
        | ProductTypeEnum
        | undefined
    return productType === ProductTypeEnum.BUNDLE_PRODUCT
}

const getBaseUrl = (param?: IBaseUrlParam): string => {
    const { config, absolute = false } = param || {}
    const storeConfig = config || getStoreConfig()

    let baseUrl = ''
    if (absolute) {
        baseUrl = storeConfig.base.url.baseUrl
        baseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl
    }

    return baseUrl
}

const urlHandler = ({ model, urlStructure, options }: IUrlHandler): string => {
    const {
        slugOverride,
        config,
        absolute = false,
        urlStructure: urlStructureOverride
    } = options || {}
    const baseUrl = getBaseUrl({ absolute, config })

    return (urlStructureOverride || urlStructure).reduce(
        (urlCurrentPath: string, urlNextPath: string) => {
            if (urlNextPath.startsWith(SLUG_DELIMITER)) {
                const slug = urlNextPath.slice(1, -1)
                const { newSlug, newValue, resolver } =
                    slugOverride?.[slug] || {}

                const fieldName = newSlug ? newSlug : slug
                let fieldValue

                if (typeof resolver === 'function') {
                    fieldValue = resolver()
                } else if (newValue) {
                    fieldValue = newValue
                } else {
                    fieldValue = model?.[fieldName as keyof TInput]
                }
                return `${urlCurrentPath}/${fieldValue}`
            }

            return `${urlCurrentPath}/${urlNextPath}`
        },
        baseUrl
    )
}

const handlers: IHandlers[] = [
    {
        canHandle: (model) => isGiftCard(model),
        getUrl: (params) =>
            urlHandler({ ...params, urlStructure: GIFT_CARD_URL_STRUCTURE })
    },
    {
        canHandle: (model) => isBundleProduct(model),
        getUrl: (params) =>
            urlHandler({
                ...params,
                urlStructure: BUNLDE_PRODUCT_URL_STRUCTURE
            })
    },
    {
        canHandle: (model) =>
            Object.values(ProductTypeEnum).includes(
                model?.__typename as ProductTypeEnum
            ) || !model?.__typename,
        getUrl: (params) =>
            urlHandler({ ...params, urlStructure: PRODUCT_URL_STRUCTURE })
    },
    {
        canHandle: (model) => model?.__typename === 'CategoryTree',
        getUrl: (params) =>
            urlHandler({ ...params, urlStructure: CATEGORY_URL_STRUCTURE })
    },
    {
        canHandle: (model) => model?.__typename === 'CmsPage',
        getUrl: (params) =>
            urlHandler({ ...params, urlStructure: CMS_URL_STRUCTURE })
    }
]
export const buildUrl = ({ model, options }: IInputArgs): string => {
    let url = ''

    if (!model) {
        return ''
    }

    for (const { canHandle, getUrl } of handlers) {
        if (!canHandle(model)) {
            continue
        }

        url = getUrl({ model, options })
        if (url) {
            break
        }
    }

    return url
}
