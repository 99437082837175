import { useQuery } from '../use-query'
import type { CmsBlock, CmsBlocks, Maybe } from '@pylot-data/fwrdschema'
import type { SWRConfiguration } from 'swr'
import { RequestInit } from '@vercel/fetch'
import { GraphQLError } from '@pylot-data/graphqlFetch'
import { getCMSBlocks } from './graphql/getCMSBlocks'

export type NavigationResponse = {
    items?: Maybe<Array<Maybe<CmsBlock>>>
    isValidating: boolean
    error?: GraphQLError
}

export const useCmsBlocks = (
    identifiers: string[],
    swrOptions?: SWRConfiguration,
    fetchOptions?: RequestInit
): NavigationResponse => {
    const { data, error, isValidating } = useQuery<
        { identifiers: string[] },
        { cmsBlocks: CmsBlocks }
    >(getCMSBlocks, { identifiers }, swrOptions, fetchOptions)

    return {
        items: data?.data?.cmsBlocks?.items,
        isValidating,
        error
    }
}
