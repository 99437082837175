/* eslint-disable i18next/no-literal-string */
import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import {
    ConfigurableVariant,
    EnteredOptionInput,
    SimpleProduct
} from '@pylot-data/pylotschema'
import { ProductTileProps } from './ProductTile'
import { SelectedOptions } from '@corratech/pylot-variant-selector'
import { ProductTypeEnum } from '@pylot-data/enums/ProductTypeEnum'
import { Option } from '@pylot-data/hooks/product/use-product-ui'

const ShopNow = dynamic(() =>
    import('@components/common/Product/ShopNow/ShopNow').then(
        (mod) => mod.ShopNow
    )
)
const SellToUs = dynamic(() =>
    import('@components/common/Product/SellToUs/SellToUs').then(
        (mod) => mod.SellToUs
    )
)
const QuickAdd = dynamic(() =>
    import('@corratech/pylot-quick-add').then((mod) => mod.QuickAdd)
)

enum ProductStockStatus {
    InStock = 'IN_STOCK',
    OutOfStock = 'OUT_OF_STOCK'
}

type Props = {
    productUrl: string
    setSelectedOptions: React.Dispatch<React.SetStateAction<SelectedOptions>>
    variant: SimpleProduct | ProductTypeEnum
    quickAddOptions: Option[]
    isSellToUsVisible?: boolean
    isShopNowVisible?: boolean
    isSellToUsNotVisible?: boolean
    isConfig?: boolean
    isBundle?: boolean
    selectedProductOptions?: SelectedOptions | EnteredOptionInput[]
    selectedProductOptionsUIDs?: string[]
} & Pick<ProductTileProps, 'categoryHierarchy' | 'quickAddOption' | 'product'>

const ProductCTA: FC<Props> = ({
    isSellToUsVisible,
    isShopNowVisible,
    isSellToUsNotVisible,
    categoryHierarchy,
    quickAddOption = null,
    product,
    productUrl,
    isConfig,
    isBundle,
    selectedProductOptions,
    setSelectedOptions,
    variant,
    selectedProductOptionsUIDs,
    quickAddOptions
}) => {
    if (isSellToUsVisible) {
        return <SellToUs />
    }
    if (isSellToUsNotVisible) {
        return null
    }
    if (isShopNowVisible) {
        return (
            <ShopNow
                product={product}
                categoryHierarchy={categoryHierarchy}
                productUrl={productUrl}
            />
        )
    }
    if (product?.stock_status === ProductStockStatus.OutOfStock) {
        return null
    }
    return (
        <div
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
            onKeyDown={(e) => e.preventDefault()}
            role="button"
            tabIndex={0}
            className="my-2"
        >
            <QuickAdd
                productData={product}
                variants={product.variants as ConfigurableVariant[]}
                isConfig={!!isConfig}
                isBundle={isBundle}
                selectedOptions={selectedProductOptions}
                setSelectedOptions={setSelectedOptions}
                variant={variant}
                options={quickAddOptions}
                selectedOptionUIDs={selectedProductOptionsUIDs}
                quickAddOption={quickAddOption}
                // @ts-ignore // Added ts-ignore because it's override component.
                isPLP
                quickContainerClassName="btn-primary-type-plp"
            />
        </div>
    )
}

export default ProductCTA
