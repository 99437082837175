import { useQuery } from '@pylot-data/hooks/use-query'
import { PRICING_QUERY } from './pricingQuery'
import type { SWRConfiguration } from 'swr'
import { GraphQLError } from '@pylot-data/graphqlFetch'
import { useUser } from '@corratech/pylot-auth-manager'
import { calculateQtyRange } from './utils/calculateQtyRange'

type PricingVariableType = {
    sku: string
}

type UsePricingResult = {
    pricingData: PricingQueryOutput['productPricingData'] | undefined
    error: GraphQLError | undefined
    customPrice?: number
}

type PricingQueryOutput = {
    productPricingData: {
        [key: string]: {
            [key: string]: {
                [key: string]: string
            }
        }
    }
}

export const quantityRelatedPricing = (
    pricingData?: {
        [key: string]: {
            [key: string]: {
                [key: string]: string
            }
        }
    },
    qty?: number
): number => {
    //Based on the quantity we need to get the price
    let finalPrice = 0
    if (!pricingData) return finalPrice
    if (qty) {
        Object.entries(pricingData)?.map(([key, value]) => {
            const { isQtyInRange, exciteQty } = calculateQtyRange(key, qty)
            if (isQtyInRange || exciteQty) {
                const finalPriceString = Object?.entries(
                    value ?? {}
                )?.[0]?.[1]?.value?.trim()

                //Convert the string to number
                finalPrice = parseFloat(finalPriceString?.replace(/,/g, ''))

                return finalPrice
            }
        })
    } else {
        //Get the lowest price from the pricing table
        //Travel through the object and get the lowest price
        let lowestPrice = 0
        Object.entries(pricingData)?.map(([, value]) => {
            const finalPriceString = Object?.entries(
                value ?? {}
            )[0]?.[1]?.value?.trim()
            const price = parseFloat(finalPriceString?.replace(/,/g, ''))
            if (lowestPrice === 0 || price < lowestPrice) {
                lowestPrice = price
            }
        })
        finalPrice = lowestPrice
    }
    return finalPrice
}

export const usePricing = (
    sku: string,
    qty?: number,
    swrOptions?: SWRConfiguration
): UsePricingResult => {
    const { isSignedIn } = useUser()

    // We need this override so that we can get the token from local storage AGAIN every time this runs, on-demand
    // @ts-ignore
    const isClient = typeof window !== 'undefined'
    const pylotToken = isClient && window.localStorage.getItem('pylot_token')

    const queryData = useQuery<PricingVariableType, PricingQueryOutput>(
        isSignedIn && pylotToken ? PRICING_QUERY : null,
        {
            sku: sku
        },
        swrOptions
    )
    const pricingData = queryData?.data?.data?.productPricingData

    // Check if pricingData?.pricing_table is truthy before parsing
    const pricingTableString = pricingData?.pricing_table ?? undefined

    let parsedPricingData:
        | { [key: string]: { [key: string]: { [key: string]: string } } }
        | undefined

    if (pricingTableString) {
        parsedPricingData =
            typeof pricingTableString === 'string'
                ? JSON.parse(pricingTableString)
                : pricingTableString
    } else {
        parsedPricingData = undefined
    }

    //Get the first key of the object means as per above data need 2,123.41
    const firstObject = parsedPricingData?.[Object.keys(parsedPricingData)[0]]
    const customPrice = firstObject?.[Object.keys(firstObject)[0]]?.value
    return {
        pricingData: parsedPricingData,
        error: queryData.error,
        customPrice: qty
            ? quantityRelatedPricing(parsedPricingData, qty)
            : Number(customPrice)
    }
}
