import { getStoreCode } from '../../config/hooks/useStoreConfig'
import { useRouter } from 'next/router'

// Load file from locale folder if exists, or from default locale folder
export const usePreloadedData = (name: string): string => {
    const result = '{}'
    const { locale } = useRouter()
    // Important: do not merge store code resolution and require statement
    // (this is needed for webpack to index /data/ folder correctly)
    try {
        const storeCode = getStoreCode(locale)
        return require(`../data/${storeCode}_${name}.json`)
    } catch (e) {
        try {
            const defaultStoreCode = getStoreCode()
            return require(`../data/${defaultStoreCode}_${name}.json`)
        } catch (e) {
            return result
        }
    }
}
