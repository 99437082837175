export const calculateQtyRange = (key: string, qty?: number) => {
    const [num1, num2] = key.split(' - ')
    const startRangeNumber = +num1
    const endRangeNumber = +num2

    const isQtyInRange = qty && qty >= startRangeNumber && qty <= endRangeNumber
    let exciteQty = false
    if (qty && !startRangeNumber) {
        const [num1] = key.split(' and more')
        const startRangeNumber = +num1
        exciteQty = qty >= startRangeNumber || key === 'Any'
    }

    return { isQtyInRange, exciteQty }
}
