import {
    DEFAULT_CRITERIA_PARAMETER_NAME,
    parseSearchCriteria,
    SearchCriteria,
    SearchCriteriaFilterActions,
    serializeSearchCriteria
} from '@corratech/pylot-filters-and-sort/src/hooks/use-plp-manager'
import Image from '@corratech/pylot-image'
import {
    ConfigurableProduct,
    ConfigurableProductOptionsValues,
    ConfigurableVariant
} from '@pylot-data/pylotschema'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import s from './ProductTile.module.scss'
import { ProductStockStatus } from '@pylot-data/enums/ProductStockStatus.d'
import { useProductUI } from '@pylot-data/hooks/product/use-product-ui'
import { VariantSelector } from '@corratech/pylot-variant-selector'
import { QuickAdd } from '@corratech/pylot-quick-add'
import { buildUrl } from '@pylot-data/routing/urlBuilder'
import { useDataLayerAction } from '@corratech/pylot-tag-manager'
import { useTranslation } from 'next-i18next'
import {
    ProductTileProps,
    SwatchAttr,
    Variant
} from '@components/common/ProductTile/ProductTile'

export const PylotProductTile = ({
    product = {},
    imageParam = {},
    className,
    isPDP = false,
    quickAddOption = 'Add to Cart',
    swatchAttr = SwatchAttr.COLOR,
    categoryHierarchy,
    index // Selected product index from the PLP, required for GA4
}: ProductTileProps): ReactElement | null => {
    const isConfig = product.__typename === 'ConfigurableProduct'
    const isBundle = product.__typename === 'BundleProduct'
    const { t } = useTranslation(['common'])
    const { query } = useRouter()
    const search =
        typeof window !== 'undefined' && !isPDP
            ? new URL(window.location.href).search
            : ''
    const [urlSearchCriteria, setUrlSearchCriteria] = useState(search)
    const dataLayerAction = useDataLayerAction()

    const productUrlPath = buildUrl({
        model: product
    })

    const productUrl = `${productUrlPath}${isConfig ? urlSearchCriteria : ''}`

    const [selectedOption, setSelectedOption] = useState<SearchCriteria | null>(
        null
    )

    const colorsImagesMap = new Map()

    const fullImageUrl = useCallback(
        (imageUrl?: string): string => {
            const widthString = imageParam.width
                ? `&width=${imageParam.width}`
                : ''
            return `${imageUrl}?auto=webp&format=pjpg${widthString}`
        },
        [imageParam.width]
    )

    const [image, setImage] = useState(fullImageUrl(product.small_image?.url))

    // During the changes in URL - convert new filters to product url
    useEffect(() => {
        if (!selectedOption) {
            setUrlSearchCriteria(search)
        }
        if (selectedOption) {
            onVariantChange({
                label: selectedOption.attribute_code,
                value_index: +selectedOption.filter_value
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        query,
        search,
        selectedOption?.attribute_code,
        selectedOption?.filter_value,
        urlSearchCriteria
    ])

    useEffect(() => {
        if (product.small_image?.url) {
            setImage(fullImageUrl(product.small_image?.url))
        }
    }, [fullImageUrl, product.small_image?.url])

    if (isConfig && product.variants) {
        product.variants.map((variant: Variant) => {
            const colorVariant = variant.attributes.find(
                (variant: { code: string }) => variant?.code === swatchAttr
            )

            const img = variant.product.small_image
                ? variant.product.small_image.url
                : false

            if (colorVariant && img) {
                colorsImagesMap.set(colorVariant.label, fullImageUrl(img))
            }
        })
    }

    const onVariantChange = ({
        label,
        value_index
    }: Partial<ConfigurableProductOptionsValues>) => {
        const value = value_index!.toString()
        const img = colorsImagesMap.get(label)

        // expected output: "0"
        if (img) {
            // setSelectedLabel(label!)
            setImage(img)
        }

        const searchCriteria = isPDP
            ? []
            : parseSearchCriteria(
                  query[DEFAULT_CRITERIA_PARAMETER_NAME] as string
              )

        const idxOfChangedAttribute = searchCriteria.findIndex(
            (sAttr) => sAttr.attribute_code === swatchAttr
        )

        let newOption: SearchCriteria
        if (idxOfChangedAttribute !== -1) {
            searchCriteria[idxOfChangedAttribute].filter_value = value
            newOption = searchCriteria[idxOfChangedAttribute]
        } else {
            newOption = {
                attribute_code: swatchAttr,
                filter_action: SearchCriteriaFilterActions.EQ,
                filter_value: value
            }
            searchCriteria.push(newOption)
        }
        const newSearchCriteriaString = `?${DEFAULT_CRITERIA_PARAMETER_NAME}=${decodeURIComponent(
            serializeSearchCriteria(searchCriteria)
        )}`
        setUrlSearchCriteria(newSearchCriteriaString)
        setSelectedOption(newOption)
    }

    useEffect(() => {
        if (isPDP) return
        // get the first configurable option
        const attrConfigurableOption = options?.[0]?.values?.[0]
        // set the first configurable option as selected
        if (
            isConfig &&
            attrConfigurableOption?.value_index &&
            attrConfigurableOption?.label
        ) {
            onVariantChange(attrConfigurableOption)
        }
    }, [])

    const {
        selectedOptions,
        setSelectedOptions,
        variant,
        selectedOptionUIDs,
        options
    } = useProductUI(product)

    if (!product) return null

    return (
        <div
            className={cn(
                s['productitem-block'],
                'productitem-block',
                className
            )}
        >
            <div className={s['productitem-inner-wrapper']}>
                <div className={s['productimage-block']}>
                    <Link
                        href={productUrl}
                        className={s['productimg-link']}
                        aria-label={product.name}
                        onClick={() => {
                            if (categoryHierarchy?.length) {
                                const pdpBreadcrumbCategoriesRaw =
                                    sessionStorage.getItem(
                                        'pdpBreadcrumbCategories'
                                    ) ?? '{}'
                                const pdpBreadcrumbCategories = JSON.parse(
                                    pdpBreadcrumbCategoriesRaw
                                )
                                pdpBreadcrumbCategories[product.sku] =
                                    categoryHierarchy
                                sessionStorage.setItem(
                                    'pdpBreadcrumbCategories',
                                    JSON.stringify(pdpBreadcrumbCategories)
                                )
                            }

                            /* Triggered GA4 or GTM for SEO when user clicks on product present in list or grid */
                            dataLayerAction({
                                type: 'PRODUCT_CLICK',
                                data: {
                                    ...product,
                                    index,
                                    searchTerm: query.q,
                                    selectedOptions,
                                    selectedOptionUIDs,
                                    categoryGroup: {
                                        ...(categoryHierarchy?.length &&
                                            categoryHierarchy[
                                                categoryHierarchy.length - 1
                                            ])
                                    }
                                }
                            })
                        }}
                    >
                        <Image
                            src={image}
                            alt={product.name ? product.name : 'placeholder'}
                            style={{ objectFit: 'contain' }}
                            width={320}
                            height={320}
                            className={s['product-img']}
                        />
                    </Link>
                </div>
                <div className={s['product-details']}>
                    <h2 className={s['product-name']}>
                        <Link
                            href={productUrl}
                            className={s['productname-link']}
                            aria-label={product.name}
                            onClick={() => {
                                if (categoryHierarchy?.length) {
                                    const pdpBreadcrumbCategoriesRaw =
                                        sessionStorage.getItem(
                                            'pdpBreadcrumbCategories'
                                        ) ?? '{}'
                                    const pdpBreadcrumbCategories = JSON.parse(
                                        pdpBreadcrumbCategoriesRaw
                                    )
                                    pdpBreadcrumbCategories[product.sku] =
                                        categoryHierarchy
                                    sessionStorage.setItem(
                                        'pdpBreadcrumbCategories',
                                        JSON.stringify(pdpBreadcrumbCategories)
                                    )
                                }

                                /* Triggered GA4 or GTM for SEO when user clicks on product present in list or grid */
                                dataLayerAction({
                                    type: 'PRODUCT_CLICK',
                                    data: {
                                        ...product,
                                        index,
                                        searchTerm: query.q,
                                        selectedOptions,
                                        selectedOptionUIDs,
                                        categoryGroup: {
                                            ...(categoryHierarchy?.length &&
                                                categoryHierarchy[
                                                    categoryHierarchy.length - 1
                                                ])
                                        }
                                    }
                                })
                            }}
                        >
                            <span>{product.name}</span>
                        </Link>
                    </h2>

                    {isConfig && product.configurable_options && (
                        <div>
                            <VariantSelector
                                disableAll={
                                    (product as ConfigurableProduct)
                                        .stock_status ===
                                    ProductStockStatus.OutOfStock
                                }
                                variants={
                                    product.variants as ConfigurableVariant[]
                                }
                                options={options}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                                isPDP={false}
                                excludedOptions={['size']}
                                PLPVariantChangeHandler={onVariantChange}
                            />
                        </div>
                    )}
                </div>
                <div
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                    role="button"
                    tabIndex={0}
                    className={s['product-atc-btn']}
                >
                    {product?.stock_status === ProductStockStatus.OutOfStock ? (
                        <span className="out-of-stock">
                            {t('Out of Stock')}
                        </span>
                    ) : (
                        <QuickAdd
                            productData={product}
                            variants={product.variants as ConfigurableVariant[]}
                            isConfig={isConfig}
                            isBundle={isBundle}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            variant={variant}
                            options={options}
                            selectedOptionUIDs={selectedOptionUIDs}
                            quickAddOption={quickAddOption}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export const ProductItem = React.memo(
    (props: ProductTileProps) => <PylotProductTile {...props} />,
    (prevProps, newProps) =>
        JSON.stringify(prevProps) === JSON.stringify(newProps)
)

ProductItem.displayName = 'MemoProductItem'
